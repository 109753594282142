<template>
  <ModuleConfigurator />
</template>

<script>
import ModuleConfigurator from './components/ModuleConfigurator'

export default {
  name: 'App',

  components: {
    ModuleConfigurator,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
@import url("https://use.typekit.net/yqx7aef.css");

.v-application {
  font-family: rubik, sans-serif;
  font-weight: 400;
  font-style: normal;
}
</style>
