import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                //primary: "#FFB3AB",
                primary: "#FF8C80",
                secondary: "#8F1FFF",
                // secondary: "#9E9E9E",
                // accent: "#673AB7",
                // error: "#f44336",
                // warning: "#ffeb3b",
                // info: "#2196f3",
                // success: "#4caf50"
            },
            dark: {
                //primary: "#FFB3AB",
                //primary: "#FFB3AB",
                primary: "#FF8C80",
                secondary: "#8F1FFF",
                // secondary: "#9E9E9E",
                // accent: "#673AB7",
                // error: "#f44336",
                // warning: "#ffeb3b",
                // info: "#2196f3",
                // success: "#4caf50"
            }
        }
    }
});
// primary: "#E91E63",
//     secondary: "#9C27b0",
